import React from 'react';
import { useState, useEffect } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import user_ic from '../../Assets/img/user.png';
import edit_ic from '../../Assets/img/edit-ic.png';
import back_ic from '../../Assets/img/back.png';
import suspend_ic from '../../Assets/img/suspend-ic.png';
import approve_ic from '../../Assets/img/approve-ic.png';
import eye_ic from '../../Assets/img/eye-ic.png';

import Header from '../../components/blocks/Header';
import Footer from '../../components/blocks/Footer';
import Sidebar from '../../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { cmsDetails, updateCms } from '../../store/cmsSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { Link, useParams } from "react-router-dom";

const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const Edit = () => {
  const {cms_id } = useParams();
  const dispatch = useDispatch();
  const { cmsDetailData, updateStatus } = useSelector((state) => state.cms);
  //console.log(cmsDetailData);
  const [cmsid, setIdValue] = useState(0);
  const [page_name, setPageNameValue] = useState("");
  const [content, setContentValue] = useState("");

  useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Edit Cms | GigFlick";

  }, []);

  useEffect(() => {
    let dataObj = {};
    dataObj["cms_id"] = cms_id;
    const data = JSON.stringify(dataObj);
    dispatch(cmsDetails(data));
  }, []);

  useEffect(() => {
    if (cmsDetailData.success == 'yes') {
        //setFormValue(cmsDetailData.data);
        setIdValue(cmsDetailData.data.id);
        setPageNameValue(cmsDetailData.data.page_name);
        setContentValue(cmsDetailData.data.content);
    }
  }, [cmsDetailData]);


  const onInputChange = (e) => {
    let { name, value } = e.target;
    if (e.target.value.trim() != "") {
      $('#' + name + '-error').html('');
    }
    setPageNameValue(value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
        let dataObjPass = {};
        dataObjPass['cms_id'] = cmsid;
        dataObjPass['page_name'] = page_name;
        dataObjPass['content'] = content;

        const data = JSON.stringify(dataObjPass);
        dispatch(updateCms(data));
    }
  }

  const handleValidation = () => {
    let errors = {};
    let errorClassName = {};
    let formIsValid = true;

    if (!page_name) {
      formIsValid = false;
      errors["page_name"] = "Please enter page name.";
      errorClassName["page_name"] = "has-error";
      $('#page_name-error').html(errors["page_name"]);
    }
    if (!content) {
      formIsValid = false;
      errors["content"] = "Please enter content.";
      errorClassName["content"] = "has-error";
      $('#content-error').html(errors["content"]);
    }
    return formIsValid;
  }

return (
  <>
      <div id="page-container" className="header-fixed-top sidebar-visible-lg-full cmspage">
        <Sidebar />
        <div id="main-container">
          <Header />
          <div id="page-content" style={{ minHeight: '966px' }}>
            <div className="row block full user-block edit-profile">
              <div className="profile-sec">
                <div className="user-header">
                  <div class="user-details">
                    <div class="block-option">
                        <Link to={"/cms/"} class="back-btn">
                            <img class="" src={back_ic}/>
                        </Link> 
                    </div>
                    <h2>Edit CMS</h2>
                  </div>

                  <form  id="add_form" name="add_form" className="form-horizontal edit-form" onSubmit={handleSubmit} method="POST">
                    <input type="hidden" name="cms_id" id="cms_id" value={cmsid || ""} />
                    <div className="form-group">
                      <label className="col-xs-12 col-xs-12 col-sm-5 col-md-4 col-lg-3 control-label vcenter" for="First Name">Page Name</label>
                      <div className="col-xs-12 col-xs-12 col-sm-6 col-md-6 col-lg-5 vcenter">
                        <input type="text" name="page_name" id="page_name" className="form-control" value={page_name || ""} onChange={onInputChange} />
                        <div id="page_name-error" className="error help-block animation-slideUp"></div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-xs-12 col-xs-12 col-sm-5 col-md-4 col-lg-3 control-label vcenter" for="Last Name">Page Description</label>
                      <div className="col-xs-12 col-xs-12 col-sm-6 col-md-6 col-lg-5 vcenter">
                        <CKEditor
                                editor={ClassicEditor}
                                height="80vh"
                                id="header"
                                data={content}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log("Editor is ready to use!", editor);
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setContentValue(data);
                                  if(data !="")
                                  {
                                      $('#content-error').html('');
                                  }
                                }}
                            />
                        <div id="content-error" className="error help-block animation-slideUp"></div>
                      </div>
                    </div>
                    <div className="form-group form-actions">
                      <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3 col-lg-2 col-sm-offset-5 col-md-offset-4 col-lg-offset-3">
                          {updateStatus === 'loading' ? (
                            <button type="submit" className=" common-btn btn-effect-ripple"
                            style={{overflow: "hidden", position: "relative"}} disabled><i class="fa fa-spinner" aria-hidden="true"></i> Update</button>
                          )
                          :
                            <button type="submit" className=" common-btn btn-effect-ripple"
                            style={{overflow: "hidden", position: "relative"}}>Update</button>
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
)
}
export default Edit;