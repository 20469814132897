import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../../Assets/img/no_image.jpg';
import back_ic from '../../Assets/img/back.png';
import Header from '../../components/blocks/Header';
import Footer from '../../components/blocks/Footer';
import Sidebar from '../../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { seekerDetail} from '../../store/seekersSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { Link, useParams } from "react-router-dom";

const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const SeekersDetail = () => {
  const dispatch = useDispatch();
  const { seekerDetailData} = useSelector((state) => state.jobseekers);
  const { seeker_id } = useParams();
  useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Job Seekers Details | GigFlick";

  }, []);

  useEffect(() => {
    let dataObj = {};
    dataObj["seeker_id"] = seeker_id;
    const data = JSON.stringify(dataObj);
    dispatch(seekerDetail(data));
  }, []);
return (
  <>
      <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
        <Sidebar />
        <div id="main-container">
          <Header />
          <div id="page-content" style={{ minHeight: '966px' }}>
            <div class="block user-block full user-header">
                <div class="user-details">
                    <div class="block-option">
                        <Link to={"/job_seekers/"} class="back-btn">
                            <img class="" src={back_ic}/>
                        </Link> 
                    </div>
                    <h2>Job Seeker Details</h2>
                </div>
                <div class="row details">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>First Name:</strong>
                        <address>{seekerDetailData.first_name}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Last Name:</strong>
                        <address>{seekerDetailData.last_name}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Email:</strong>
                        <address>{seekerDetailData.email}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Phone Number:</strong>
                        <address>{seekerDetailData.phone_number}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> About:</strong>
                        <address>{seekerDetailData.about?seekerDetailData.about:"-"}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> Address:</strong>
                        <address>{seekerDetailData.address?seekerDetailData.address:"-"}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> Gender:</strong>
                        <address>
                        {seekerDetailData.gender == 0 ? (
                          <span>Male</span>
                        ) : (
                          <span>Female</span>
                        )}
                        </address>
                    </div>

                    

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Is Suspend:</strong>
                        <address>
                        {seekerDetailData.is_suspend == 0 ? (
                            <span>Unsuspend</span>
                          ) : (
                            <span>Suspend</span>
                          )}
                        </address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Is Verify:</strong>
                        <address>
                        {seekerDetailData.is_verify == 0 ? (
                            <span>Verify</span>
                          ) : (
                            <span>Unverify</span>
                          )}
                        </address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Created Date:</strong>
                        <address>{seekerDetailData.create_date?seekerDetailData.create_date:"-"}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Profile Picture:</strong>
                        <address>
                          {seekerDetailData.profile_picture == "" ? (
                            <img src={user_ic} class="user-profile" alt="Image" style={{width:"100px",height:"100px"}} />
                          ) : (
                            <img src={seekerDetailData.profile_picture} class="user-profile" alt="Image" style={{width:"100px",height:"100px"}} />
                          )}
                          
                        </address>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
      <ToastContainer />
    </>
)
}
export default SeekersDetail;