import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { baseUrl } from "../config";
import axios from "axios";
import { toast } from "react-toastify";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const authenticationSlice = createSlice({
    name: 'admin',
    initialState: {
        data: [],
        adminData: {},
        status: STATUSES.IDLE,
        dashboardData:{},
        appData: {},
        btn_status: STATUSES.IDLE,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            
            .addCase(adminLogin.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(adminLogin.fulfilled, (state, action) => {
                state.adminData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(adminLogin.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(forgotPassword.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.adminData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(changePassword.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.adminData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(getUserDetail.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getUserDetail.fulfilled, (state, action) => {
                state.adminData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(getUserDetail.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(updateProfile.pending, (state, action) => {
                state.btn_status = STATUSES.LOADING;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.adminData = action.payload;
                state.btn_status = STATUSES.IDLE;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.btn_status = STATUSES.ERROR;
            })
            .addCase(dashboardCount.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(dashboardCount.fulfilled, (state, action) => {
                state.dashboardData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(dashboardCount.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(getAppDetail.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAppDetail.fulfilled, (state, action) => {
                state.appData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(getAppDetail.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(updateSetting.pending, (state, action) => {
                state.btn_status = STATUSES.LOADING;
            })
            .addCase(updateSetting.fulfilled, (state, action) => {
                state.appData = action.payload;
                state.btn_status = STATUSES.IDLE;
            })
            .addCase(updateSetting.rejected, (state, action) => {
                state.btn_status = STATUSES.ERROR;
            });

            
    },
});

export default authenticationSlice.reducer;

// Thunks
export const adminLogin = createAsyncThunk('adminLogin', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/signin', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            console.log(response.data)
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('login_user_id', response.data.data.id);
            localStorage.setItem('first_name', response.data.data.first_name);
            localStorage.setItem('last_name', response.data.data.last_name);
            localStorage.setItem('email', response.data.data.email);
            window.location.href = "/dashboard";
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const forgotPassword = createAsyncThunk('forgotPassword', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/forgot_password', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            document.getElementById("forgotForm").reset();
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});


export const changePassword = createAsyncThunk('changePassword', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/change_password', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            document.getElementById("password_form").reset();
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const getUserDetail = createAsyncThunk('getUserDetail', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/adminDetail', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            return response.data.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const updateProfile = createAsyncThunk('updateProfile', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/update_profile', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            document.getElementById("adminFullName").innerHTML = response.data.data.first_name+' '+response.data.data.last_name;
            document.getElementById("adminSubname").innerHTML = response.data.data.first_name+' '+response.data.data.last_name;
            document.getElementById("adminEmail").innerHTML = response.data.data.email;
            localStorage.setItem('first_name', response.data.data.first_name);
            localStorage.setItem('last_name', response.data.data.last_name);
            localStorage.setItem('email', response.data.data.email);
            return response.data.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const dashboardCount = createAsyncThunk('dashboardCount', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/dashboardCount', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            return response.data.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const getAppDetail = createAsyncThunk('getAppDetail', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/appDetail', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            return response.data.data;
        }
        else {
            return response.data.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const updateSetting = createAsyncThunk('updateSetting', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/updateSetting', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});