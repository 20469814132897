import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import login from '../Assets/img/login_logo.png';
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { adminLogin } from '../store/authenticationSlice';
var sha512 = require('js-sha512');
const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const initialStateLogin = {
    email: "",
    password: "",
}
const Login = () => {

    useEffect(() => {
        if (isLogin === "true") {
            window.location.href = "/dashboard";
        }
        document.title = "Login | GigFlick";
        $('#main_body').css('background-color', '#0075F2');
        $( "#page-wrapper" ).addClass( "commonwrapper" ); 
        $( "#root" ).addClass( "rootClass" ); 
    }, [])

    const [loginValue, setLoginValue] = useState(initialStateLogin);
    const [passwordShown, setPasswordShown] = useState(false);
    const { status } = useSelector((state) => state.admin);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const dispatch = useDispatch();

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if (handleLoginValidation()) {

            const form = e.target;
            const formData = new FormData(form);
            let dataObj = {};
            for (const [key, value] of formData.entries()) {
                if (key === "password") {
                    dataObj[key] = sha512(value);
                }
                else {
                    dataObj[key] = value;
                }
            }
            let dataObjPass = {};
            dataObjPass['email'] = dataObj.email;
            dataObjPass['password'] = dataObj.password;
            const data = JSON.stringify(dataObjPass);
            dispatch(adminLogin(data));
        }
       
    }

    const handleLoginValidation = () => {
        let errors = {};
        let errorClassName = {};
        let formIsValid = true;

        if (!loginValue.email) {
            formIsValid = false;
            errors["email"] = "Please enter email address.";
            errorClassName["email"] = "has-error";
            $('#email-error').html(errors["email"]);

        }
        else {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(loginValue.email)) {

                formIsValid = false;
                errors["email"] = "Invalid Email Address.";
                errorClassName["email"] = "has-error";
                $('#email-error').html(errors["email"]);
            }
        }

        if (!loginValue.password) {
            formIsValid = false;
            errors["password"] = "Please enter password.";
            errorClassName["password"] = "has-error";
            $('#password-error').html(errors["password"]);
        }

        return formIsValid;
    }

    const onInputChangeLogin = (e) => {
        let { name, value } = e.target;
        if (e.target.value.trim() != "") {
            $('#' + name + '-error').html('');
        }
        setLoginValue({ ...loginValue, [name]: value });
    }
    
    return (
        <>
          <section className="section">
            <div className="container">
                <div id="login-container">
                    <div className="container">
                        <div className="row row-flex justify-content-center">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 r-dnone">
                                <div className="pattern-cross-dots-lg"></div>
                            </div>

                            <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-4 landscape">
                                <div className="block animation-fadeInQuickInv">

                                    <div>
                                        <div className="login-logo text-center animation-slideDown ">
                                           <img src={login} style={{width:"100%",marginBottom:"0px"}} />
                                        </div>
                                     
                                        <div className="block-title text-center mb-5" style={{border: "0" }}>
                                            <h1 className="mb-0">Welcome Back!</h1>
                                        </div>
                                       
                                        <form id="form-login" method="post" className="form-horizontal" onSubmit={handleLoginSubmit} encType="multipart/form-data">
                                            <div className="form-group">
                                                <div className="col-xs-12 mb-3">
                                                    <input type="text" id="email" name="email" className="form-control" placeholder="Email" onChange={onInputChangeLogin}/>
                                                    <div id="email-error" className="error help-block animation-slideUp"></div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="col-xs-12">
                                                    <input type={passwordShown ? "text" : "password"} id="password" name="password" className="form-control" placeholder="Password" onChange={onInputChangeLogin}   />
                                                    <div id="password-error" className="error help-block animation-slideUp"></div>
                                                    <div className="eye-vi"> 
                                                        <i className="fa-solid fa-eye-slash" id="eye" onClick={togglePasswordVisiblity}></i>
                                                    </div>
                                                 </div>
                                            </div>
                                            <div className="form-group form-actions">
                                               
                                                <div className="col-xs-12 text-right btn-margin">
                                                    {status === 'loading' ? (
                                                        <button type="submit" className="common-btn btn-effect-ripple btn-sm btn-primary" disabled><i class="fa fa-spinner" aria-hidden="true"></i> Let's Go</button>
                                                    )
                                                    :
                                                        <button type="submit" className="common-btn btn-effect-ripple btn-sm btn-primary">Let's Go</button>
                                                    }
                                                </div>
                                                 <div className="col-xs-12 forgot mt-4">
                                                   <p className="text-center "><Link to="/forgot_password" className="forgot" title="Forgot your password?">Forgot Password?</Link></p>
                                                </div>
                                            </div>
                                        </form>
                                       
                                    </div>
                                </div>
                               
                            </div>

                           <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 r-dnone">
                                <div className="pattern-cross-dots-lg"></div>
                            </div>
                        </div>
                    </div>
            
                </div>
            </div>
        </section>
        <ToastContainer />
        </>
    )
}
export default Login;