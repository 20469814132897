import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../../Assets/img/user.png';
import delete_ic from '../../Assets/img/delete-ic.png';
import suspend_ic from '../../Assets/img/suspend-ic.png';
import approve_ic from '../../Assets/img/approve-ic.png';
import eye_ic from '../../Assets/img/eye-ic.png';

import Header from '../../components/blocks/Header';
import Footer from '../../components/blocks/Footer';
import Sidebar from '../../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { getJobSeekers, jobSeekersMultiDelete, jobSeekersDelete,jobSeekersVerify, jobSeekersSuspend } from '../../store/seekersSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const Seekers = () => {
  const dispatch = useDispatch();
  const { seekersData, totalRecord, deleteData, verifyData,suspendData } = useSelector((state) => state.jobseekers);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Job Seekers | GigFlick";

  }, [])

  const handleChange = id => () => {
    setChecked(prev => {
      if (prev.includes(id)) {
        return prev.filter(x => x !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setChecked(items.map(li => li.id));
    if (isCheckAll) {
      setChecked([]);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  const fetchData = async (page) => {
    var search_text = $('#search_text').val();
    let dataObj = {};
    dataObj["page"] = page;
    dataObj["search_text"] = search_text;
    const data = JSON.stringify(dataObj);
    dispatch(getJobSeekers(data));
  }

  const handlePageChange = page => {
    setChecked([]);
    fetchData(page, perPage);
    setActivePage(page)
  }

  const deleteAll = () => {
    var delete_id = checked.toString();
    if(delete_id == "")
    {
        toast.error('Please select at least one check box!.');
        return false;
    }

    if(window.confirm('Are you sure you want to delete these seeker(s)?'))
    {
      let dataObj = {};
      dataObj["delete_id"] = delete_id;
      const data = JSON.stringify(dataObj);
      dispatch(jobSeekersMultiDelete(data));
    }
  }

  const search = (e) => {
    setChecked([]);
    let dataObj = {};
    dataObj["page"] = 1;
    dataObj["search_text"] = e.target.value;
    const data = JSON.stringify(dataObj);
    dispatch(getJobSeekers(data));
  }


  useEffect(() => {
    if (seekersData.success == 'yes') {
        setChecked([]);
        setIsLoaded(true);
        setItems(seekersData.data);
        setTotalRows(seekersData.total_record);
    }
    else
    {
      setIsLoaded(true);
    }
  }, [seekersData]);

  useEffect(() => {
    if (deleteData.success == 'yes') {
      fetchData(1);
      setActivePage(1)
    }
  }, [deleteData]);

  const delete_single = (id) => {
    /*$(".delete_"+id).html('<i class="fa fa-refresh fa-spin"></i>');
    $(".delete_"+id).prop('disabled', true);*/

    let dataObj = {};
    dataObj["delete_id"] = id;
    const data = JSON.stringify(dataObj);
    dispatch(jobSeekersDelete(data));
  }

  const verify_seeker = (id) => {

    var status = $('.verify_'+id).attr("data-id");
    if(status == 0)
    {
        var msg='unverify';
    }
    else
    {
        var msg='verify';
    }

    if(window.confirm('Are you sure you want to '+msg+' this seeker?'))
    {
      $(".verify_"+id).html('<i class="fa fa-spinner" aria-hidden="true"></i>');
      $(".verify_"+id).prop('disabled', true);

      let dataObj = {};
      dataObj["seeker_id"] = id;
      const data = JSON.stringify(dataObj);
      dispatch(jobSeekersVerify(data));
    }
  }

  useEffect(() => {
    

    if (verifyData.success == 'yes') {
        var id = verifyData.data.seeker_id;
        $(".verify_"+id).html('<img class="" src="'+approve_ic+'"/>');
        $(".verify_"+id).prop('disabled', false);
        var is_verify = verifyData.data.is_verify;
        if(is_verify == 1)
        {
            $( ".verify_"+id).removeClass( "bg-green" ); 
            $( ".verify_"+id).addClass( "bg-gray" ); 
        }
        else
        {
            $( ".verify_"+id).removeClass( "bg-gray" ); 
            $( ".verify_"+id).addClass( "bg-green" ); 
        }
       
        $('.verify_'+id).attr('data-id', is_verify);
    }
    else if (verifyData.success == 'no')
    {
       var id = verifyData.data.seeker_id;
       $(".verify_"+id).html('<img class="" src="'+approve_ic+'"/>');
       $(".verify_"+id).prop('disabled', false);
    }
  }, [verifyData]);

  const suspend_seeker = (id) => {

    var status = $('.suspend_'+id).attr("data-id");
    if(status == 0)
    {
        var msg='suspend';
    }
    else
    {
        var msg='unsuspend';
    }

    if(window.confirm('Are you sure you want to '+msg+' this seeker?'))
    {
      $(".suspend_"+id).html('<i class="fa fa-spinner" aria-hidden="true"></i>');
      $(".suspend_"+id).prop('disabled', true);

      let dataObj = {};
      dataObj["seeker_id"] = id;
      const data = JSON.stringify(dataObj);
      dispatch(jobSeekersSuspend(data));
    }
  }

  useEffect(() => {
    

    if (suspendData.success == 'yes') {
        var id = suspendData.data.seeker_id;
        $(".suspend_"+id).html('<img class="" src="'+suspend_ic+'"/>');
        $(".suspend_"+id).prop('disabled', false);
        var is_suspend = suspendData.data.is_suspend;
        if(is_suspend == 1)
        {
            $( ".suspend_"+id).removeClass( "bg-green" ); 
            $( ".suspend_"+id).addClass( "bg-gray" ); 
        }
        else
        {
            $( ".suspend_"+id).removeClass( "bg-gray" ); 
            $( ".suspend_"+id).addClass( "bg-green" ); 
        }

        $('.suspend_'+id).attr('data-id', is_suspend);
    }
    else if (suspendData.success == 'no')
    {
       var id = suspendData.data.seeker_id;
       $(".suspend_"+id).html('<img class="" src="'+suspend_ic+'"/>');
       $(".suspend_"+id).prop('disabled', false);
    }
  }, [suspendData]);
  
  
return (
  <>
      <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
        <Sidebar />
        <div id="main-container">
          <Header />
          <div id="page-content" style={{ minHeight: '966px' }}>
            <div className="block full user-block">
              <div id="all_messages"></div>
              <div className="user-list-sec">
                <div className="user-head-block">
                  <div className="user-header">
                    <h2 className="">Job Seekers(<span id="totalRecords">{totalRows}</span>)</h2>
                  </div>
                  <div className="user-head-block">
                    
                  </div>
                </div>

                <div className="table-responsive">
                  <form name="product_form" id="product_form" method="post">
                    <div className="row">
                      <div className="col-sm-6 col-xs-6 delete_row">
                        <div className="dataTables_length">
                          <button type="button" onClick={deleteAll} className="btn btn-danger delete_multiple"><i class="fa fa-fw fa-times-circle"></i> Delete</button>

                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-6 delete_row">
                        <div id="cities_datatable_filter" className="dataTables_filter">
                          <label>
                            <div className="input-group">
                              <input type="search" className="form-control" id="search_text" placeholder="Search" onChange={search} aria-controls="cities_datatable" /><span className="input-group-addon"><i className="fa fa-search"></i></span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <table className="table table-striped table-vcenter">
                      <thead>
                        <tr className="custom-trow">
                          <th className="text-center" style={{ width: "8%" }}><label className="csscheckbox csscheckbox-primary">
                            <input type="checkbox" id="checkall" onChange={handleSelectAll} name="checkall" checked={items.length !=0 && checked.length === items.length} /><span></span></label></th>
                          <th className="text-left" style={{ width: "15%" }}>First Name</th>
                          <th className="text-left" style={{ width: "15%" }}>Last Name</th>
                          <th className="text-left" style={{ width: "15%" }}>Email</th>
                          <th className="text-left" style={{ width: "15%" }}>Phone Number</th>
                          <th className="text-left" style={{ width: "15%" }}>Date</th>
                          <th className="text-center" style={{ width: "15%" }}><i className="fa fa-flash"></i></th>
                        </tr>
                      </thead>

                      <tbody>

                        {
                          items.map((row, index) => {
                            return (
                              <tr role="row" key={index} className={index % 2 === 0 ? "odd" : "even"}>
                                <td className="sorting_1">
                                  <center><label className="csscheckbox csscheckbox-primary">
                                    <input type="checkbox" id="chk" name="chk" onChange={handleChange(row.id)} checked={checked.includes(row.id)} /><span></span></label></center>
                                </td>
                                <td>{row.first_name}</td>
                                <td>{row.last_name}</td>
                                <td>{row.email}</td>
                                <td>{row.phone_number}</td>
                                <td>{row.create_date}</td>
                                <td>
                                 
                                  <center>
                                    
                                    <Link to={"/job_seekers_detail/" + row.id} title="View Detail" class="d-inline" style={{ overflow: "hidden", position: "relative" }} data-original-title="View Detail">
                                      <div class="view-ic"> 
                                        <img class="" src={eye_ic}/>
                                      </div>
                                    </Link>&nbsp;
                                    {row.is_verify == 0 ? (
                                      <a href="Javascript:void(0)" onClick={() => verify_seeker(row.id)} data-toggle="tooltip" title="Tap to unverify" class="d-inline" style={{ overflow: "hidden", position: "relative" }} data-original-title="Tap to unverify"> 
                                        <div class={'view-ic bg-green verify_'+row.id} data-id={row.is_verify}> 
                                          <img class="" src={approve_ic}/>
                                        </div>
                                      </a>
                                    ) : (
                                      <a href="Javascript:void(0)" onClick={() => verify_seeker(row.id)} data-toggle="tooltip" title="Tap to verify" class="d-inline" style={{ overflow: "hidden", position: "relative" }} data-original-title="Tap to verify"> 
                                        <div class={'view-ic bg-gray verify_'+row.id} data-id={row.is_verify}> 
                                          <img class="" src={approve_ic}/>
                                        </div>
                                      </a>
                                    )}
                                    &nbsp;
                                    {row.is_suspend == 0 ? (
                                    <a href="Javascript:void(0)" onClick={() => suspend_seeker(row.id)} data-toggle="tooltip" title="Tap to suspend" class="d-inline" style={{ overflow: "hidden", position: "relative" }} data-original-title="Tap to suspend"> 
                                      <div class={'view-ic bg-green suspend_'+row.id} data-id={row.is_suspend}> 
                                        <img class="" src={suspend_ic}/>
                                      </div>
                                    </a>
                                    ) : (
                                      <a href="Javascript:void(0)" onClick={() => suspend_seeker(row.id)} data-toggle="tooltip" title="Tap to unsuspend" class="d-inline" style={{ overflow: "hidden", position: "relative" }} data-original-title="Tap to unsuspend"> 
                                        <div class={'view-ic bg-gray suspend_'+row.id} data-id={row.is_suspend}> 
                                          <img class="" src={suspend_ic}/>
                                        </div>
                                      </a>
                                    )}
                                    &nbsp;
                                    <a href="Javascript:void(0)" onClick={() => {if(window.confirm('Are you sure you want to delete this seeker?')){ delete_single(row.id)};}} data-toggle="tooltip" title="Tap to Delete" class={'d-inline delete_'+row.id} style={{ overflow: "hidden", position: "relative" }} data-original-title="Tap to delete" id="removes">
                                      <div class="del-ic">
                                        <img class="" src={delete_ic}/>
                                      </div>
                                    </a>
                                  </center>
                                  
                                </td>
                              </tr>
                            )
                          })
                        }

                        {items.length == 0 ? (
                          <tr id="no_record" className="text-center">
                            <td colspan="7" align="cenetr">No Job Seekers Found.</td>
                          </tr>
                        ) : (
                          ''
                        )}

                      </tbody>
                    </table>
                  </form>
                  {items.length != 0 ? (
                  <div className="paging_bootstrap">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={totalRows}
                      pageRangeDisplayed={4}
                      onChange={handlePageChange}
                      prevPageText="Prev"
                      nextPageText="Next"
                    />
                  </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
)
}
export default Seekers;