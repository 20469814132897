import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { baseUrl } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import $ from 'jquery';

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const contactSlice = createSlice({
    name: 'contactus',
    initialState: {
        data: [],
        contactusData: [],
        deleteData:{},
        totalRecord: 0,
        status: STATUSES.IDLE,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            
            .addCase(getContactusList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getContactusList.fulfilled, (state, action) => {
                state.contactusData = action.payload;
                state.totalRecord = action.payload.total_record;
                state.status = STATUSES.IDLE;
            })
            .addCase(getContactusList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(contactMultiDelete.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(contactMultiDelete.fulfilled, (state, action) => {
                state.deleteData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(contactMultiDelete.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(contactDelete.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(contactDelete.fulfilled, (state, action) => {
                state.deleteData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(contactDelete.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            });
    },
});

export default contactSlice.reducer;

// Thunks
export const getContactusList = createAsyncThunk('getContactusList', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/contactusList', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const contactMultiDelete = createAsyncThunk('contactMultiDelete', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/contactusMultiDelete', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const contactDelete = createAsyncThunk('contactDelete', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/contactusDelete', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});