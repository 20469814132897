import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../Assets/img/user.png';
import Header from '../components/blocks/Header';
import Footer from '../components/blocks/Footer';
import Sidebar from '../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardCount } from '../store/authenticationSlice';
import { Link } from 'react-router-dom';
const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const Dashboard = () => {
 const dispatch = useDispatch();
 const { dashboardData } = useSelector((state) => state.admin);

 useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Dashboard | GigFlick";

      dispatch(dashboardCount());
  }, [])



return (
<>
  <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
    <Sidebar />
    <div id="main-container">
      <Header />
      <div id="page-content" style={{ minHeight: '966px' }}>
        <div className="content-header">
          <div className="row">
            <div>
              <div className="header-section">
                <h1>Hello, admin</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row widget-sec">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <Link to="/job_providers" className="widget">
              <div className="widget-content widget-content-mini">
                <div className="dashbord-in">
                  <h5>Job Providers</h5>
                  <h1 className="widget-heading">
                    <strong><span data-toggle="counter" data-to={dashboardData.providerTotal}>{dashboardData.providerTotal}</span></strong>
                  </h1>
                </div>
              </div>
              <div className="widget-icon">
                <img src={user_ic} />
              </div>
            </Link>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <Link to="/job_seekers" className="widget">
              <div className="widget-content widget-content-mini">
                <div className="dashbord-in">
                  <h5>Job Seekers</h5>
                  <h1 className="widget-heading">
                    <strong><span data-toggle="counter" data-to="{dashboardData.seekerTotal}">{dashboardData.seekerTotal}</span></strong>
                  </h1>
                </div>
              </div>
              <div className="widget-icon">
                <img src={user_ic} />
              </div>
            </Link>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <Link to="/jobs" className="widget">
              <div className="widget-content widget-content-mini">
                <div className="dashbord-in">
                  <h5>Job Posts</h5>
                  <h1 className="widget-heading">
                    <strong><span data-toggle="counter" data-to="{dashboardData.jobsCount}">{dashboardData.jobsCount}</span></strong>
                  </h1>
                </div>
              </div>
              <div className="widget-icon">
                <img src={user_ic} />
              </div>
            </Link>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <Link to="/cms" className="widget">
              <div className="widget-content widget-content-mini">
                <div className="dashbord-in">
                  <h5>CMS Pages</h5>
                  <h1 className="widget-heading">
                    <strong><span data-toggle="counter" data-to="{dashboardData.cmsCount}">{dashboardData.cmsCount}</span></strong>
                  </h1>
                </div>
              </div>
              <div className="widget-icon">
                <img src={user_ic} />
              </div>
            </Link>
          </div>

        </div>
      </div>
    </div>
  </div>
</>
)
}
export default Dashboard;