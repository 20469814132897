import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../../Assets/img/no_image.jpg';
import pdf_ic from '../../Assets/img/pdf_ic.png';
import download_ic from '../../Assets/img/download_ic.png';
import back_ic from '../../Assets/img/back.png';
import Header from '../../components/blocks/Header';
import Footer from '../../components/blocks/Footer';
import Sidebar from '../../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { applicantProfile} from '../../store/jobsSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { Link, useParams } from "react-router-dom";

const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const ApplicantProfile = () => {
  const dispatch = useDispatch();
  const { applicantProfileDetail} = useSelector((state) => state.jobs);
  
  const {apply_id } = useParams();
  useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Applicant Profile | GigFlick";

  }, []);

  useEffect(() => {
    let dataObj = {};
    dataObj["apply_id"] = apply_id;
    const data = JSON.stringify(dataObj);
    dispatch(applicantProfile(data));
  }, []);

 
return (
  <>
      <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
        <Sidebar />
        <div id="main-container">
          <Header />
          <div id="page-content" style={{ minHeight: '966px' }}>
            <div class="block user-block full user-header">
                <div class="user-details">
                    <div class="block-option">
                        <Link to={"/job_detail/"+applicantProfileDetail.job_id} class="back-btn">
                            <img class="" src={back_ic}/>
                        </Link> 
                    </div>
                    <h2>Applicant Profile</h2>
                </div>
                <div class="row details">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 profile-bg pattern-triangles-md">
                      <div class="profile-box">
                          <div class="user-profile-block">

                          {applicantProfileDetail.profile_picture == "" ? (
                            <img src={user_ic} class="form-control-static user-profile" alt="Image1"/>
                          ) : (
                            <img src={applicantProfileDetail.profile_picture} class="form-control-static user-profile" alt="Image"/>
                          )}
                          </div>
                          <div class="user-name-email">
                              <h1>{applicantProfileDetail.seeker_name}</h1>
                              
                          </div>
                      </div>
                  </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Email:</strong>
                        <address>{applicantProfileDetail.email}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Phone Number:</strong>
                        <address>{applicantProfileDetail.email}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> Gender:</strong>
                        <address>
                        {applicantProfileDetail.gender == 0 ? (
                          <span>Male</span>
                        ) : (
                          <span>Female</span>
                        )}
                        </address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> Address:</strong>
                        <address>{applicantProfileDetail.address?applicantProfileDetail.address:"-"}</address>
                    </div>

                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> About:</strong>
                        <address>{applicantProfileDetail.about?applicantProfileDetail.about:"-"}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> Cover Letter:</strong>
                        <address>{applicantProfileDetail.cover_letter?applicantProfileDetail.cover_letter:"-"}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> Resume:</strong>
                        
                        {applicantProfileDetail.resume_url_name == "" ? (
                          <address>-</address>
                        ) : (
                          <Link to="route" onClick={(event) => { event.preventDefault(); window.open(applicantProfileDetail.resume_url); }} class="pdf_link"><address className="pdf"><img src={pdf_ic} style={{width: "35px",marginRight: "10px"}} /> {applicantProfileDetail.resume_url_name?applicantProfileDetail.resume_url_name:"-"} <img src={download_ic} style={{width: "25px",marginLeft: "10px"}} /></address></Link>
                        )}
                       
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
      <ToastContainer />
    </>
)
}
export default ApplicantProfile;