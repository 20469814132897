import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { baseUrl } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import $ from 'jquery';

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const seekersSlice = createSlice({
    name: 'jobseekers',
    initialState: {
        data: [],
        seekersData: [],
        deleteData:{},
        verifyData:{},
        suspendData:{},
        seekerDetailData:{},
        totalRecord: 0,
        status: STATUSES.IDLE,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            
            .addCase(getJobSeekers.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getJobSeekers.fulfilled, (state, action) => {
                state.seekersData = action.payload;
                state.totalRecord = action.payload.total_record;
                state.status = STATUSES.IDLE;
            })
            .addCase(getJobSeekers.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobSeekersMultiDelete.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobSeekersMultiDelete.fulfilled, (state, action) => {
                state.deleteData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobSeekersMultiDelete.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobSeekersDelete.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobSeekersDelete.fulfilled, (state, action) => {
                state.deleteData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobSeekersDelete.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobSeekersVerify.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobSeekersVerify.fulfilled, (state, action) => {
                state.verifyData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobSeekersVerify.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobSeekersSuspend.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobSeekersSuspend.fulfilled, (state, action) => {
                state.suspendData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobSeekersSuspend.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(seekerDetail.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(seekerDetail.fulfilled, (state, action) => {
                state.seekerDetailData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(seekerDetail.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            });

            

            
    },
});

export default seekersSlice.reducer;

// Thunks
export const getJobSeekers = createAsyncThunk('getJobSeekers', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/job_seekers', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobSeekersMultiDelete = createAsyncThunk('jobSeekersMultiDelete', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobSeekersMultiDelete', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobSeekersDelete = createAsyncThunk('jobSeekersDelete', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobSeekersDelete', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobSeekersVerify = createAsyncThunk('jobSeekersVerify', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobSeekersVerify', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobSeekersSuspend = createAsyncThunk('jobSeekersSuspend', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobSeekersSuspend', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const seekerDetail = createAsyncThunk('seekerDetail', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/seekerDetail', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            return response.data.data;
        }
        else {
            toast.error(response.data.message);
            return response.data.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});
