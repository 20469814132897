import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../../Assets/img/user.png';
import edit_ic from '../../Assets/img/edit-ic.png';
import suspend_ic from '../../Assets/img/suspend-ic.png';
import approve_ic from '../../Assets/img/approve-ic.png';
import eye_ic from '../../Assets/img/eye-ic.png';

import Header from '../../components/blocks/Header';
import Footer from '../../components/blocks/Footer';
import Sidebar from '../../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { getCmsList } from '../../store/cmsSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const Cms = () => {
  const dispatch = useDispatch();
  const { cmsData, totalRecord } = useSelector((state) => state.cms);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Cms | GigFlick";

  }, []);

  useEffect(() => {
    fetchData(1);
  }, []);

  const fetchData = async (page) => {
    var search_text = $('#search_text').val();
    let dataObj = {};
    dataObj["page"] = page;
    dataObj["search_text"] = search_text;
    const data = JSON.stringify(dataObj);
    dispatch(getCmsList(data));
  }

  const handlePageChange = page => {
    fetchData(page, perPage);
    setActivePage(page)
  }

  useEffect(() => {
    if (cmsData.success == 'yes') {
        setIsLoaded(true);
        setItems(cmsData.data);
        setTotalRows(cmsData.total_record);
    }
    else
    {
      setIsLoaded(true);
    }
  }, [cmsData]);

  const search = (e) => {
    let dataObj = {};
    dataObj["page"] = 1;
    dataObj["search_text"] = e.target.value;
    const data = JSON.stringify(dataObj);
    dispatch(getCmsList(data));
  }
return (
  <>
      <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
        <Sidebar />
        <div id="main-container">
          <Header />
          <div id="page-content" style={{ minHeight: '966px' }}>
            <div className="block full user-block">
              <div id="all_messages"></div>
              <div className="user-list-sec">
                <div className="user-head-block">
                  <div className="user-header">
                    <h2 className="">Cms(<span id="totalRecords">{totalRows}</span>)</h2>
                  </div>
                  <div className="user-head-block">
                    
                  </div>
                </div>

                <div className="table-responsive">
                  <form name="jobs_form" id="jobs_form" method="post">
                    <div className="row">
                      <div className="col-sm-6 col-xs-6 delete_row">
                        <div className="dataTables_length">
                         
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-6 delete_row">
                        <div id="jobs_datatable_filter" className="dataTables_filter">
                          <label>
                            <div className="input-group">
                              <input type="search" className="form-control" id="search_text" placeholder="Search" onChange={search} aria-controls="cities_datatable" /><span className="input-group-addon"><i className="fa fa-search"></i></span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <table className="table table-striped table-vcenter cms">
                      <thead>
                        <tr className="custom-trow">
                          <th className="text-center" style={{ width: "8%" }}>No</th>
                          <th className="text-left" style={{ width: "20%" }}>Page Name</th>
                          <th className="text-left" style={{ width: "60%" }}>Page Content</th>
                          <th className="text-center" style={{ width: "15%" }}><i className="fa fa-flash"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          items.map((row, index) => {
                            return (
                              <tr role="row" key={index} className={index % 2 === 0 ? "odd" : "even"}>
                                <td className="sorting_1">
                                  <center>{index+1}</center>
                                </td>
                                <td>{row.page_name}</td>
                                <td>
                                  <div dangerouslySetInnerHTML={{ __html: row.content }} />
                                </td>
                                <td>
                                  <center>
                                    
                                    <Link to={"/cms_edit/" + row.id} title="Edit" class="d-inline" style={{ overflow: "hidden", position: "relative" }} data-original-title="Edit">
                                      <div class="view-ic"> 
                                        <img class="" src={edit_ic}/>
                                      </div>
                                    </Link>
                                  </center>
                                </td>
                              </tr>
                            )
                          })
                        }

                        {items.length == 0 ? (
                          <tr id="no_record" className="text-center">
                            <td colspan="4" align="cenetr">No Cms Found.</td>
                          </tr>
                        ) : (
                          ''
                        )}
                      </tbody>
                    </table>
                  </form>
                  {items.length != 0 ? (
                  <div className="paging_bootstrap">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={totalRows}
                      pageRangeDisplayed={4}
                      onChange={handlePageChange}
                      prevPageText="Prev"
                      nextPageText="Next"
                    />
                  </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
)
}
export default Cms;