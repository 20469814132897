import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { baseUrl } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import $ from 'jquery';

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const providersSlice = createSlice({
    name: 'jobproviders',
    initialState: {
        data: [],
        providersData: [],
        deleteData:{},
        verifyData:{},
        suspendData:{},
        providerDetailData:{},
        totalRecord: 0,
        status: STATUSES.IDLE,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            
            .addCase(getJobProviders.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getJobProviders.fulfilled, (state, action) => {
                state.providersData = action.payload;
                state.totalRecord = action.payload.total_record;
                state.status = STATUSES.IDLE;
            })
            .addCase(getJobProviders.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobProvidersMultiDelete.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobProvidersMultiDelete.fulfilled, (state, action) => {
                state.deleteData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobProvidersMultiDelete.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobProvidersDelete.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobProvidersDelete.fulfilled, (state, action) => {
                state.deleteData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobProvidersDelete.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobProvidersVerify.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobProvidersVerify.fulfilled, (state, action) => {
                state.verifyData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobProvidersVerify.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobProvidersSuspend.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobProvidersSuspend.fulfilled, (state, action) => {
                state.suspendData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobProvidersSuspend.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(providerDetail.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(providerDetail.fulfilled, (state, action) => {
                state.providerDetailData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(providerDetail.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            });

            

            
    },
});

export default providersSlice.reducer;

// Thunks
export const getJobProviders = createAsyncThunk('getJobProviders', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/job_providers', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobProvidersMultiDelete = createAsyncThunk('jobProvidersMultiDelete', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobProvidersMultiDelete', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobProvidersDelete = createAsyncThunk('jobProvidersDelete', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobProvidersDelete', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobProvidersVerify = createAsyncThunk('jobProvidersVerify', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobProvidersVerify', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobProvidersSuspend = createAsyncThunk('jobProvidersSuspend', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobProvidersSuspend', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const providerDetail = createAsyncThunk('providerDetail', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/providerDetail', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            return response.data.data;
        }
        else {
            toast.error(response.data.message);
            return response.data.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});
