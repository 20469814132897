import React, { Component } from 'react';
import nav_mini from '../../Assets/img/nav-mini.png';
import nav_full from '../../Assets/img/nav-full.png';
import setting_ic from '../../Assets/img/setting-ic.png';
import profile_ic from '../../Assets/img/profile.jpg';
import profile_user from '../../Assets/img/profile-user.png';
import logout_ic from '../../Assets/img/logout-ic.png';
import { Link } from 'react-router-dom';
import $ from "jquery";

const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";
const email = localStorage.getItem('email') ? localStorage.getItem('email') : ""
const first_name = localStorage.getItem('first_name') ? localStorage.getItem('first_name') : ""
const last_name = localStorage.getItem('last_name') ? localStorage.getItem('last_name') : ""

const Header = () => {
const showhide = () => {

var menu_show = $('#menu_show').val();
if(menu_show ==0)
{
  $('.header-fixed-top').removeClass("sidebar-visible-lg-full");
  $('.header-fixed-top').addClass("sidebar-visible-lg-mini");
  $('#menu_show').val(1);
}
else
{
  $('.header-fixed-top').addClass("sidebar-visible-lg-full");
  $('.header-fixed-top').removeClass("sidebar-visible-lg-mini");
  $('#menu_show').val(0);
}
};

const showhideMobile = () => {

var menu_show = $('#menu_show').val();
if(menu_show ==0)
{
  $('.header-fixed-top').addClass("sidebar-visible-xs");
  $('#menu_show').val(1);
}
else
{
  $('.header-fixed-top').removeClass("sidebar-visible-xs");
  $('#menu_show').val(0);
}
};

const logout = () => {
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.setItem('login_user_id', '');
    localStorage.setItem('first_name', '');
    localStorage.setItem('last_name', '');
    localStorage.setItem('email', '');
    window.location.href = "/";
}
return (
<>
  <header className="navbar navbar-inverse navbar-fixed-top">
    <ul className="nav navbar-nav-custom">
      <li className="web_view">
        <a href="javascript:void(0)" onClick={showhide}>
          <img className="animation-fadeInRight" id="sidebar-toggle-mini" src={nav_mini} />
          <img className="animation-fadeInRight" id="sidebar-toggle-full" src={nav_full} />
          <input type="hidden" id="menu_show" value="0" />
        </a>
      </li>
      <li className="mobile_view" style={{display: "none"}}>
        <a href="javascript:void(0)" onClick={showhideMobile}>
          <img className="animation-fadeInRight" id="sidebar-toggle-mini" src={nav_mini} />
          <img className="animation-fadeInRight" id="sidebar-toggle-full" src={nav_full} />
          <input type="hidden" id="menu_show" value="0" />
        </a>
      </li>
      <li className="hidden-xs animation-fadeInQuick">
      </li>
    </ul>
    <ul className="nav navbar-nav-custom pull-right right-navbar">
      <li className="dropdown">
        <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown">
          <div className="profile">
            <div className="profile-image me-2">
              <img src={profile_ic} className="w-100" />
            </div>
            <div className="user-name">
              
              <h5 className="mb-0" id="adminFullName" style={{textTransform: "capitalize"}}>{first_name} {last_name}</h5>
            </div>
          </div>
        </a>
        <ul className="dropdown-menu dropdown-menu-right">
          <li className="dropdown-header">
            <div className="profile">
              <div className="profile-image me-2">
                <img src={profile_ic} className="w-100" />
              </div>
              <div className="user-name">
                <h5 className="mb-0" id="adminSubname" style={{textTransform: "capitalize"}}>{first_name} {last_name}</h5>
                <p className="mb-0" id="adminEmail">{email}</p>
              </div>
            </div>
          </li>
          <hr className="custom-hr" />
          <li>
            <Link to="/edit_profile">
              <span className="dropdown-ic"><img src={profile_user} /></span>
              <span className="dropdown-text">Edit Profile</span>
            </Link>
          </li>

          <li>
            <Link to="/change_password">
              <span className="dropdown-ic"><img src={setting_ic} /></span>
              <span className="dropdown-text">Change Password</span>
            </Link>
          </li>
          <li>
            <Link to="/app_settings">
              <span className="dropdown-ic"><img src={setting_ic} /></span>
              <span className="dropdown-text">App Setting</span>
            </Link>
          </li>
          <li>
            <a href="/" onClick={logout}>
              <span className="dropdown-ic"><img src={logout_ic} /></span>
              <span className="dropdown-text">Logout</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </header>
</>
)
}
export default Header;