import { useState, useEffect } from 'react';
import logo from '../../Assets/img/login_logo.png';
import small from '../../Assets/img/small_logo.png';
import dashboard from '../../Assets/img/dashboard-ic.png';
import user from '../../Assets/img/user-ic.png';
import job_post from '../../Assets/img/job_post.png';
import job_provider from '../../Assets/img/job_provider.png';
import job_seekers from '../../Assets/img/job_seekers.png';
import cms from '../../Assets/img/cms-ic.png';
import { Link } from 'react-router-dom';
const Sidebar = () => {
const currentPanel = window.location.pathname.split('/');
const [isDashboardActive,setDashboardValue] = useState("");
const [isJobProvidersActive,setJobProvidersValue] = useState("");
const [isJobSeekersActive,setJobSeekersValue] = useState("");
const [isJobsActive,setJobsValue] = useState("");
const [isCmsActive,setCmsValue] = useState("");
const [isContactActive,setContactValue] = useState("");

useEffect(()=>{
        if(currentPanel[1] == "dashboard")
        {
            setDashboardValue("active");
        }
        else if(currentPanel[1] == "job_providers")
        {
            setJobProvidersValue("active");
        }
        else if(currentPanel[1] == "job_providers_detail")
        {
            setJobProvidersValue("active");
        }
        else if(currentPanel[1] == "job_seekers")
        {
            setJobSeekersValue("active");
        }
        else if(currentPanel[1] == "job_seekers_detail")
        {
            setJobSeekersValue("active");
        }
        else if(currentPanel[1] == "jobs")
        {
            setJobsValue("active");
        }
        else if(currentPanel[1] == "job_detail")
        {
            setJobsValue("active");
        }
        else if(currentPanel[1] == "applicant_profile")
        {
            setJobsValue("active");
        }
        else if(currentPanel[1] == "cms")
        {
            setCmsValue("active");
        }
        else if(currentPanel[1] == "cms_edit")
        {
            setCmsValue("active");
        }
        else if(currentPanel[1] == "contactus")
        {
            setContactValue("active");
        }
        else
        {
            setDashboardValue("header");
        }

    },[])

return (
<>
  <div id="sidebar">
    <div id="sidebar-brand" className="themed-background">
      <Link to="/dashboard" className="sidebar-title text-center">
        <img className="sidebar-logo-full" src={logo} />
        <img className="sidebar-logo-mini" src={small} />
      </Link>
    </div>
    <div className="slimScrollDiv">
      <div id="sidebar-scroll">
        <div className="sidebar-content">
          <ul className="sidebar-nav">
            <li>
              <Link to="/dashboard" className={isDashboardActive} >
                <div className="sidebar-box">
                  <img className="side-ic" src={dashboard} />
                  <span className="sidebar-nav-mini-hide">Dashboard</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/job_providers" className={isJobProvidersActive}>
                <div className="sidebar-box">
                  <img className="side-ic" src={job_provider} />
                  <span className="sidebar-nav-mini-hide">Job Providers</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/job_seekers" className={isJobSeekersActive}>
                <div className="sidebar-box">
                  <img className="side-ic" src={job_seekers} />
                  <span className="sidebar-nav-mini-hide">Job Seekers</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/jobs" className={isJobsActive}>
                <div className="sidebar-box">
                  <img className="side-ic" src={job_post} />
                  <span className="sidebar-nav-mini-hide">Job Post</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/cms" className={isCmsActive}>

                <div className="sidebar-box">
                  <img className="side-ic" src={cms} />
                  <span className="sidebar-nav-mini-hide">Cms Pages</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/contactus" className={isContactActive}>

                <div className="sidebar-box">
                  <img className="side-ic" src={cms} />
                  <span className="sidebar-nav-mini-hide">Contact Us</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</>
)
}
export default Sidebar;