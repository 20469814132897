import { configureStore } from '@reduxjs/toolkit';
import authenticationSlice from './authenticationSlice';
import providersSlice from './providersSlice';
import seekersSlice from './seekersSlice';
import jobsSlice from './jobsSlice';
import cmsSlice from './cmsSlice';
import contactSlice from './contactSlice';
const store = configureStore({
    reducer: {
        admin: authenticationSlice,
        jobproviders: providersSlice,
        jobseekers: seekersSlice,
        jobs: jobsSlice,
        cms: cmsSlice,
        contactus: contactSlice,
    },
});

export default store;
