import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import login from '../Assets/img/login_logo.png';
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../store/authenticationSlice';
const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";
const initialState = {
    email: "",
}
const ForgotPassword = () => {
    const [formValue, setFormValue] = useState(initialState);
    const { status } = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    useEffect(() => {

        if (isLogin === "true") {
            window.location.href = "/dashboard";
        }
        document.title = "Forgot Password | GigFlick";
        $('#main_body').css('background-color', '#0075F2');
        $( "#page-wrapper" ).addClass( "commonwrapper" ); 
        $( "#root" ).addClass( "rootClass" ); 
    }, [])

    const onInputChange = (e) => {
        let { name, value } = e.target;
        if (e.target.value.trim() != "") {
            $('#' + name + '-error').html('');
        }
        setFormValue({ ...formValue, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            const form = e.target;
            const formData = new FormData(form);
            let dataObj = {};
            for (const [key, value] of formData.entries()) {
                dataObj[key] = value;
            }
            const data = JSON.stringify(dataObj);
            dispatch(forgotPassword(data));
        }
    }

    const handleValidation = () => {
        let errors = {};
        let errorClassName = {};
        let formIsValid = true;

        if (!formValue.email) {
            formIsValid = false;
            errors["email"] = "Please enter email address.";
            errorClassName["email"] = "has-error";
            $('#email-error').html(errors["email"]);

        }
        else {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(formValue.email)) {

                formIsValid = false;
                errors["email"] = "Invalid Email Address.";
                errorClassName["email"] = "has-error";
                $('#email-error').html(errors["email"]);

            }
        }

        return formIsValid;
    }
    return (
        <>
          <section class="section"> 
            <div class="container">
                <div id="login-container">
                    <div class="container">
                        <div class="row row-flex justify-content-center">
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 r-dnone">
                                <div class="pattern-cross-dots-lg"></div>
                            </div>

                            <div class="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-4 landscape">
                                <div class="block animation-fadeInQuickInv">

                                    <div> 
                                        <div class="login-logo text-center animation-slideDown ">
                                           <img src={login} style={{width:"100%",marginBottom: "0px"}} />
                                        </div>
                                       
                                        <div class="block-title text-center" style={{border: "0" }}>
                                            <h1 class="mb-1">Forgot Password?</h1> 
                                            <p>Enter your Valid Email Address</p>
                                        </div>
                                        
                                        <form id="forgotForm" name="forgotForm"  method="post" onSubmit={handleSubmit} class="form-horizontal" novalidate="novalidate">
                                            <div class="form-group">
                                                <div class="col-xs-12">
                                                    <input type="text" id="email" name="email" class="form-control" placeholder="Enter your email.." onChange={onInputChange} />
                                                    <div id="email-error" className="error help-block animation-slideUp"></div>
                                                </div>
                                            </div>
                                            <div class="form-group form-actions">
                                                <div class="col-xs-12 text-right  btn-margin">
                                                    {status === 'loading' ? (
                                                        <button type="submit" class="common-btn btn-effect-ripple btn-sm btn-primary" style={{overflow: "hidden", position: "relative"}} disabled><i class="fa fa-spinner" aria-hidden="true"></i> Submit</button>
                                                    )
                                                    :
                                                        <button type="submit" class="common-btn btn-effect-ripple btn-sm btn-primary" style={{overflow: "hidden", position: "relative"}}>Submit</button>
                                                    }
                                                </div>
                                            </div>
                                        </form>

                                        
                                         <footer class=" text-center animation-pullUp account">
                                        Already have an account? <Link to="/login">Click here</Link>   to Sign in.
                                         </footer>
                                    </div>

                                </div>
                              
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 r-dnone"> 
                                <div class="pattern-cross-dots-lg">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ToastContainer />
        </>
    )
}
export default ForgotPassword;