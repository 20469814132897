import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../Assets/img/user.png';
import Header from '../components/blocks/Header';
import Footer from '../components/blocks/Footer';
import Sidebar from '../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { changePassword } from '../store/authenticationSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var sha512 = require('js-sha512');
const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const initialPasswordState = {
  password: "",
  new_password: "",
  confirm_password: "",
}

const Dashboard = () => {

 const [passwordValue, setPasswordValue] = useState(initialPasswordState);
 const [passwordShown, setPasswordShown] = useState(false);
 const [newpasswordShown, setNewPasswordShown] = useState(false);
 const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
 const { status } = useSelector((state) => state.admin);
 const dispatch = useDispatch();
 useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Change Password | GigFlick"
  }, []);

const onInputChangePassword = (e) => {
  let { name, value } = e.target;
  if (e.target.value.trim() != "") {
    $('#' + name + '-error').html('');
  }
  setPasswordValue({ ...passwordValue, [name]: value });
}
  
const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (handleValidationPassword()) {
      const form = e.target;
      const formData = new FormData(form);
      let dataObj = {};
      for (const [key, value] of formData.entries()) {
        if (key !== "user_id") {
          dataObj[key] = sha512(value);
        }
        else {
          dataObj[key] = value;
        }
      }
      const data = JSON.stringify(dataObj);
      dispatch(changePassword(data));
    }
  }

  const handleValidationPassword = () => {
    let errors = {};
    let errorClassName = {};
    let formIsValid = true;

    if (!passwordValue.password) {
      formIsValid = false;
      errors["password"] = "Current password field is require.";
      errorClassName["password-error"] = "has-error";
      $('#password-error').html(errors["password"]);
      //console.log("Password");
    }

    if (!passwordValue.new_password) {
      formIsValid = false;
      errors["new_password"] = "New password field is require.";
      errorClassName["new_password-error"] = "has-error";
      $('#new_password-error').html(errors["new_password"]);
      //console.log("New Password");
    }

    if (!passwordValue.confirm_password) {
      formIsValid = false;
      errors["confirm_password"] = "Confirm new password field is require.";
      errorClassName["confirm_password-error"] = "has-error";
      $('#confirm_password-error').html(errors["confirm_password"]);
    }

    if ((typeof passwordValue.new_password !== "undefined") && (typeof passwordValue.confirm_password !== "undefined")) {
      if (passwordValue.new_password !== passwordValue.confirm_password) {
        formIsValid = false;
        errors["confirm_password"] = "New password and re-type password does not match.";
        errorClassName["confirm_password-error"] = "has-error";
        $('#confirm_password-error').html(errors["confirm_password"]);
      }
    }
    return formIsValid;
  }

  const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
  const toggleNewPasswordVisiblity = () => {
        setNewPasswordShown(newpasswordShown ? false : true);
    };
  const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmpasswordShown ? false : true);
    };
return (
<>
  <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
    <Sidebar />
    <div id="main-container">
      <Header />
      <div id="page-content" style={{ minHeight: '966px' }}>
        <div className="row">
          <div className="">
            <div className="block full user-block user-header">
              <div className="">
                <h2>Change Password</h2>
              </div>
              <form method="post" id="password_form" className="form-horizontal edit-form" onSubmit={handlePasswordSubmit} encType="multipart/form-data">
              <input type="hidden" name="user_id" id="user_id" value={admin_user_id}  />
              <div className="form-group">
                <label className="col-xs-12 col-xs-12 col-sm-5 col-md-4 col-lg-3 control-label vcenter" for="Current Password">Current Password</label>
                <div className="col-xs-12 col-xs-12 col-sm-6 col-md-6 col-lg-5 vcenter">
                  <input name="password" type={passwordShown ? "text" : "password"} id="password" size="30" maxLength="45"  className="form-control" onChange={onInputChangePassword}/>
                  <div id="password-error" className="error help-block animation-slideUp"></div>
                  <div className="eye-vi"> 
                      <i className="fa-solid fa-eye-slash" id="eye" onClick={togglePasswordVisiblity}></i>
                  </div>
                </div>
              </div>
                  
              <div className="form-group">
                        <label className="col-xs-12 col-xs-12 col-sm-5 col-md-4 col-lg-3 control-label vcenter" for="New Password">New Password</label>
                        <div className="col-xs-12 col-xs-12 col-sm-6 col-md-6 col-lg-5 vcenter">
                          <input name="new_password" type={newpasswordShown ? "text" : "password"} id="new_password" size="30" maxLength="45" className="form-control" onChange={onInputChangePassword}/>
                          <div id="new_password-error" className="error help-block animation-slideUp"></div>
                          <div className="eye-vi"> 
                              <i className="fa-solid fa-eye-slash" id="eye" onClick={toggleNewPasswordVisiblity}></i>
                          </div>
                       </div>   
                    </div>
                        
                    <div className="form-group">
                        <label className="col-xs-12 col-xs-12 col-sm-5 col-md-4 col-lg-3 control-label vcenter" for="Confirm New Password">Confirm New Password</label>
                        <div className="col-xs-12 col-xs-12 col-sm-6 col-md-6 col-lg-5 vcenter">
                          <input name="confirm_password" type={confirmpasswordShown ? "text" : "password"} id="confirm_password" size="30" maxLength="45" className="form-control" onChange={onInputChangePassword}/>
                          <div id="confirm_password-error" className="error help-block animation-slideUp"></div>
                          <div className="eye-vi"> 
                              <i className="fa-solid fa-eye-slash" id="eye" onClick={toggleConfirmPasswordVisiblity}></i>
                          </div>
                        </div>  
                    </div>
                        
                   <div className="form-group form-actions">
                      <div className="row">
                  <div className="col-xs-6 col-sm-3 col-md-3 col-lg-2 col-sm-offset-5 col-md-offset-4 col-lg-offset-3">
                    {status === 'loading' ? (
                      <button type="submit" className="common-btn btn-effect-ripple" style={{overflow: "hidden", position: "relative"}} disabled><i class="fa fa-spinner" aria-hidden="true"></i> Update</button>
                    )
                    :
                      <button type="submit" className="common-btn btn-effect-ripple" style={{overflow: "hidden", position: "relative"}}>Update</button>
                    }
                  </div>
                </div>
              </div>
            </form>
            </div>  
          </div>
        </div>

      </div>
    </div>
  </div>
  <ToastContainer />
</>
)
}
export default Dashboard;