import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { baseUrl } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import $ from 'jquery';

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const jobsSlice = createSlice({
    name: 'jobs',
    initialState: {
        data: [],
        jobsData: [],
        deleteData:{},
        jobDetailData:{},
        totalRecord: 0,
        status: STATUSES.IDLE,
        applicationData: [],
        appTotalRecord: 0,
        applicantProfileDetail:{}
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            
            .addCase(getJobsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getJobsList.fulfilled, (state, action) => {
                state.jobsData = action.payload;
                state.totalRecord = action.payload.total_record;
                state.status = STATUSES.IDLE;
            })
            .addCase(getJobsList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobMultiDelete.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobMultiDelete.fulfilled, (state, action) => {
                state.deleteData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobMultiDelete.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobDelete.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobDelete.fulfilled, (state, action) => {
                state.deleteData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobDelete.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobDetail.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobDetail.fulfilled, (state, action) => {
                state.jobDetailData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobDetail.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(jobApplicationList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobApplicationList.fulfilled, (state, action) => {
                state.applicationData = action.payload;
                state.appTotalRecord = action.payload.total_record;
                state.status = STATUSES.IDLE;
            })
            .addCase(jobApplicationList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(applicantProfile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(applicantProfile.fulfilled, (state, action) => {
                state.applicantProfileDetail = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(applicantProfile.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            });
    },
});

export default jobsSlice.reducer;

// Thunks
export const getJobsList = createAsyncThunk('getJobsList', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobsList', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobMultiDelete = createAsyncThunk('jobMultiDelete', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobsMultiDelete', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const jobDelete = createAsyncThunk('jobDelete', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobDelete', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});


export const jobDetail = createAsyncThunk('jobDetail', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobDetail', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            return response.data.data;
        }
        else {
            toast.error(response.data.message);
            return response.data.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});


export const jobApplicationList = createAsyncThunk('jobApplicationList', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/jobApplicationList', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const applicantProfile = createAsyncThunk('applicantProfile', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/applicantProfile', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            return response.data.data;
        }
        else {
            toast.error(response.data.message);
            return response.data.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});