import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../../Assets/img/user.png';
import delete_ic from '../../Assets/img/delete-ic.png';
import suspend_ic from '../../Assets/img/suspend-ic.png';
import approve_ic from '../../Assets/img/approve-ic.png';
import eye_ic from '../../Assets/img/eye-ic.png';

import Header from '../../components/blocks/Header';
import Footer from '../../components/blocks/Footer';
import Sidebar from '../../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { getContactusList, contactMultiDelete, contactDelete } from '../../store/contactSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const Contactus = () => {
  const dispatch = useDispatch();
  const { contactusData, totalRecord, deleteData } = useSelector((state) => state.contactus);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Contact Us | GigFlick";

  }, [])
  
  const handleChange = id => () => {
    setChecked(prev => {
      if (prev.includes(id)) {
        return prev.filter(x => x !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setChecked(items.map(li => li.id));
    if (isCheckAll) {
      setChecked([]);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  const fetchData = async (page) => {
    var search_text = $('#search_text').val();
    let dataObj = {};
    dataObj["page"] = page;
    dataObj["search_text"] = search_text;
    const data = JSON.stringify(dataObj);
    dispatch(getContactusList(data));
  }

  const handlePageChange = page => {
    setChecked([]);
    fetchData(page, perPage);
    setActivePage(page)
  }

  const deleteAll = () => {
    var delete_id = checked.toString();
    if(delete_id == "")
    {
        toast.error('Please select at least one check box!.');
        return false;
    }

    if(window.confirm('Are you sure you want to delete these contact(s)?'))
    {
      let dataObj = {};
      dataObj["delete_id"] = delete_id;
      const data = JSON.stringify(dataObj);
      dispatch(contactMultiDelete(data));
    }
  }

  useEffect(() => {
    if (deleteData.success == 'yes') {
      fetchData(1);
      setActivePage(1)
    }
  }, [deleteData]);

  const search = (e) => {
    setChecked([]);
    let dataObj = {};
    dataObj["page"] = 1;
    dataObj["search_text"] = e.target.value;
    const data = JSON.stringify(dataObj);
    dispatch(getContactusList(data));
  }

  const delete_single = (id) => {
    let dataObj = {};
    dataObj["delete_id"] = id;
    const data = JSON.stringify(dataObj);
    dispatch(contactDelete(data));
  }

  useEffect(() => {
    if (contactusData.success == 'yes') {
        setChecked([]);
        setIsLoaded(true);
        setItems(contactusData.data);
        setTotalRows(contactusData.total_record);
    }
    else
    {
      setIsLoaded(true);
    }
  }, [contactusData]);
return (
  <>
      <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
        <Sidebar />
        <div id="main-container">
          <Header />
          <div id="page-content" style={{ minHeight: '966px' }}>
            <div className="block full user-block">
              <div id="all_messages"></div>
              <div className="user-list-sec">
                <div className="user-head-block">
                  <div className="user-header">
                    <h2 className="">Contact Us(<span id="totalRecords">{totalRows}</span>)</h2>
                  </div>
                  <div className="user-head-block">
                    
                  </div>
                </div>

                <div className="table-responsive">
                  <form name="contact_form" id="contact_form" method="post">
                    <div className="row">
                      <div className="col-sm-6 col-xs-6 delete_row">
                        <div className="dataTables_length">
                          <button type="button" onClick={deleteAll} className="btn btn-danger delete_multiple"><i class="fa fa-fw fa-times-circle"></i> Delete</button>

                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-6 delete_row">
                        <div id="jobs_datatable_filter" className="dataTables_filter">
                          <label>
                            <div className="input-group">
                              <input type="search" className="form-control" id="search_text" placeholder="Search" onChange={search} aria-controls="cities_datatable" /><span className="input-group-addon"><i className="fa fa-search"></i></span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <table className="table table-striped table-vcenter">
                      <thead>
                        <tr className="custom-trow">
                          <th className="text-center" style={{ width: "5%" }}><label className="csscheckbox csscheckbox-primary">
                          <input type="checkbox" id="checkall" onChange={handleSelectAll} name="checkall" checked={items.length !=0 && checked.length === items.length} /><span></span></label></th>
                          <th className="text-left" style={{ width: "15%" }}>Full Name</th>
                          <th className="text-left" style={{ width: "15%" }}>Email</th>
                          <th className="text-left" style={{ width: "40%" }}>Message</th>
                          <th className="text-left" style={{ width: "10%" }}>Type</th>
                          <th className="text-center" style={{ width: "8%" }}><i className="fa fa-flash"></i></th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          items.map((row, index) => {
                            return (
                              <tr role="row" key={index} className={index % 2 === 0 ? "odd" : "even"}>
                                <td className="sorting_1">
                                  <center><label className="csscheckbox csscheckbox-primary">
                                    <input type="checkbox" id="chk" name="chk" onChange={handleChange(row.id)} checked={checked.includes(row.id)} /><span></span></label></center>
                                </td>
                                <td>{row.full_name}</td>
                                <td>{row.email}</td>
                                <td>{row.message}</td>
                                <td>
                                  {row.type == 1 ? (
                                    <span>Seeker</span>
                                  ) : (
                                    <span>Provider</span>
                                  )}
                                </td>
                                <td>
                                 
                                  <center>
                                    <a href="Javascript:void(0)" onClick={() => {if(window.confirm('Are you sure you want to delete this contact?')){ delete_single(row.id)};}} data-toggle="tooltip" title="Tap to Delete" class={'d-inline delete_'+row.id} style={{ overflow: "hidden", position: "relative" }} data-original-title="Tap to delete" id="removes">
                                      <div class="del-ic">
                                        <img class="" src={delete_ic}/>
                                      </div>
                                    </a>
                                  </center>
                                  
                                </td>
                              </tr>
                            )
                          })
                        }

                        {items.length == 0 ? (
                          <tr id="no_record" className="text-center">
                            <td colspan="6" align="cenetr">No Contact Us Found.</td>
                          </tr>
                        ) : (
                          ''
                        )}

                      </tbody>
                    </table>
                  </form>
                  {items.length != 0 ? (
                  <div className="paging_bootstrap">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={totalRows}
                      pageRangeDisplayed={4}
                      onChange={handlePageChange}
                      prevPageText="Prev"
                      nextPageText="Next"
                    />
                  </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
)
}
export default Contactus;