import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../../Assets/img/no_image.jpg';
import eye_ic from '../../Assets/img/eye-ic.png';
import pdf_ic from '../../Assets/img/pdf_download_ic.png';
import back_ic from '../../Assets/img/back.png';
import Header from '../../components/blocks/Header';
import Footer from '../../components/blocks/Footer';
import Sidebar from '../../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { jobDetail, jobApplicationList} from '../../store/jobsSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { Link, useParams } from "react-router-dom";

const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const JobsDetail = () => {
  const dispatch = useDispatch();
  const { jobDetailData, applicationData, appTotalRecord} = useSelector((state) => state.jobs);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const {job_id } = useParams();
  useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "Job Details | GigFlick";

  }, []);

  useEffect(() => {
    let dataObj = {};
    dataObj["job_id"] = job_id;
    const data = JSON.stringify(dataObj);
    dispatch(jobDetail(data));
  }, []);

  useEffect(() => {
    fetchData(1);
  }, []);


  const fetchData = async (page) => {
    var search_text = $('#search_text').val();
    let dataObj = {};
    dataObj["page"] = page;
    dataObj["search_text"] = search_text;
    dataObj["job_id"] = job_id;
    const data = JSON.stringify(dataObj);
    dispatch(jobApplicationList(data));
  }

    const handlePageChange = page => {
        fetchData(page, perPage);
        setActivePage(page)
    }

  const search = (e) => {
    let dataObj = {};
    dataObj["page"] = 1;
    dataObj["search_text"] = e.target.value;
    dataObj["job_id"] = job_id;
    const data = JSON.stringify(dataObj);
    dispatch(jobApplicationList(data));
  }

  useEffect(() => {
    if (applicationData.success == 'yes') {
        setIsLoaded(true);
        setItems(applicationData.data);
        setTotalRows(applicationData.total_record);
    }
    else
    {
      setIsLoaded(true);
    }
  }, [applicationData]);

return (
  <>
      <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
        <Sidebar />
        <div id="main-container">
          <Header />
          <div id="page-content" style={{ minHeight: '966px' }}>
            <div class="block user-block full user-header">
                <div class="user-details">
                    <div class="block-option">
                        <Link to={"/jobs/"} class="back-btn">
                            <img class="" src={back_ic}/>
                        </Link> 
                    </div>
                    <h2>Job Details</h2>
                </div>
                <div class="row details">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Provider Name:</strong>
                        <address>{jobDetailData.first_name} {jobDetailData.last_name}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Title:</strong>
                        <address>{jobDetailData.title}</address>
                    </div>

                    

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Location:</strong>
                        <address>{jobDetailData.location}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> Start Date:</strong>
                        <address>{jobDetailData.start_date}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> End Date:</strong>
                        <address>{jobDetailData.end_date}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong> Salary Type:</strong>
                        <address>{jobDetailData.salary_type}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Salary From:</strong>
                        <address>${jobDetailData.salary_from}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Salary To:</strong>
                        <address>${jobDetailData.salary_to}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>City:</strong>
                        <address>{jobDetailData.city}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>State:</strong>
                        <address>{jobDetailData.state}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Latitude:</strong>
                        <address>{jobDetailData.latitude}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Longitude:</strong>
                        <address>{jobDetailData.longitude}</address>
                    </div>

                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Address:</strong>
                        <address>{jobDetailData.address}</address>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Created Date:</strong>
                        <address>{jobDetailData.create_date?jobDetailData.create_date:"-"}</address>
                    </div>
                    <div style={{clear:"both"}}></div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Description:</strong>
                        <address>{jobDetailData.description}</address>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 detail-text">
                        <strong>Job Image:</strong>
                        <address>
                          {jobDetailData.job_image == "" ? (
                            <img src={user_ic} class="user-profile" alt="Image"/>
                          ) : (
                            <img src={jobDetailData.job_image} class="user-profile" alt="Image"/>
                          )}
                          
                        </address>
                    </div>
                </div>

                <div className="user-list-sec" style={{marginTop: "60px"}}>
                    <div className="table-responsive">
                        <form name="application_form" id="application_form" method="post">
                            <div className="row">
                                <div className="col-sm-6 col-xs-6 delete_row">
                                    <div className="dataTables_length">
                                        <div className="user-header">
                                            <h2 className="" style={{"marginTop":"10px"}}>Job Application(<span id="totalRecords">{totalRows}</span>)</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6 delete_row">
                                    <div id="jobs_datatable_filter" className="dataTables_filter">
                                      <label>
                                        <div className="input-group">
                                          <input type="search" className="form-control" id="search_text" placeholder="Search" onChange={search} aria-controls="cities_datatable" /><span className="input-group-addon"><i className="fa fa-search"></i></span>
                                        </div>
                                      </label>
                                    </div>
                                </div>
                            </div>

                            <table className="table table-striped table-vcenter" id="application_tbl">
                              <thead>
                                <tr className="custom-trow">
                                 
                                  <th className="text-left" style={{ width: "20%" }}>Seeker Name</th>
                                  <th className="text-left" style={{ width: "20%" }}>Email</th>
                                  <th className="text-left" style={{ width: "20%" }}>Phone Number</th>
                                  <th className="text-center" style={{ width: "15%" }}>Profile Image</th>
                                  <th className="text-center" style={{ width: "15%" }}>Status</th>
                                  <th className="text-center" style={{ width: "15%" }}><i className="fa fa-flash"></i></th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  items.map((row, index) => {
                                    return (
                                      <tr role="row" key={index} className={index % 2 === 0 ? "odd" : "even"}>
                                        
                                        <td>{row.first_name} {row.last_name}</td>
                                        <td>{row.email}</td>
                                        <td>{row.phone_number}</td>
                                        <td><center><img src={row.profile_picture} style={{width:"50px",height:"50px", borderRadius: "8px"}} /></center></td>
                                        <td>
                                            <center>
                                            {(() => {

                                                if (row.status == 1) {
                                                  return (
                                                    <div style={{color:"#115486"}}>Interview Schedule</div>
                                                  )

                                                } else if (row.status == 2) {
                                                  return (
                                                    <div style={{color:"red"}}>Rejected</div>
                                                  )
                                                } else {
                                                  return (
                                                    <div style={{color:"#62aee0"}}>Applied</div>
                                                  )
                                                }
                                            })()}
                                            </center>
                                        </td>
                                        <td>
                                         
                                          <center>
                                            
                                            <Link to="route" onClick={(event) => { event.preventDefault(); window.open(row.resume_url); }} title="View PDF" class="d-inline" style={{ overflow: "hidden", position: "relative" }} data-original-title="View Detail">
                                              <div class="view-ic"> 
                                                <img class="" src={pdf_ic}/>
                                              </div>
                                            </Link>&nbsp;&nbsp;

                                            <Link to={"/applicant_profile/" + row.id} title="View Detail" class="d-inline" style={{ overflow: "hidden", position: "relative" }} data-original-title="View Detail">
                                              <div class="view-ic"> 
                                                <img class="" src={eye_ic}/>
                                              </div>
                                            </Link>
                                          </center>
                                          
                                        </td>
                                      </tr>
                                    )
                                  })
                                }

                                {items.length == 0 ? (
                                  <tr id="no_record" className="text-center">
                                    <td colspan="6" align="cenetr">No Record Found.</td>
                                  </tr>
                                ) : (
                                  ''
                                )}

                              </tbody>

                            </table>
                        </form>
                        {items.length != 0 ? (
                          <div className="paging_bootstrap">
                            <Pagination
                              activePage={activePage}
                              itemsCountPerPage={perPage}
                              totalItemsCount={totalRows}
                              pageRangeDisplayed={4}
                              onChange={handlePageChange}
                              prevPageText="Prev"
                              nextPageText="Next"
                            />
                          </div>
                          ) : (
                            ''
                          )}
                    </div>
                </div>


            </div>
        </div>
        </div>
      </div>
      <ToastContainer />
    </>
)
}
export default JobsDetail;