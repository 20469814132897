import React from 'react';
import { useState, useEffect } from 'react';
import user_ic from '../Assets/img/user.png';
import Header from '../components/blocks/Header';
import Footer from '../components/blocks/Footer';
import Sidebar from '../components/blocks/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import {getAppDetail, updateSetting } from '../store/authenticationSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var sha512 = require('js-sha512');
const admin_user_id = localStorage.getItem('login_user_id') ? localStorage.getItem('login_user_id') : "0"
const isLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : "false";

const initialState = {
  id: "",
  radius_limit: "",
}

const App_settings = () => {

 const dispatch = useDispatch();
 useEffect(() => {
      if (isLogin === "false") {
          window.location.href = "/";
      }
      document.title = "App Settings | GigFlick"
  }, []);

  const [formValue, setFormValue] = useState(initialState);
  const { appData, btn_status } = useSelector((state) => state.admin);

  useEffect(() => {
    let dataObj = {};
    const data = JSON.stringify(dataObj);
    dispatch(getAppDetail(data));
  }, [admin_user_id]);

  useEffect(() => {
    setFormValue(appData);
  }, [appData])

const onInputChange = (e) => {
    let { name, value } = e.target;
    if (e.target.value.trim() != "") {
      $('#' + name + '-error').html('');
    }
    if(name == 'radius_limit')
    {
        if (!Number(value) && value !="") {
          return;
        }
    }

    setFormValue({ ...formValue, [name]: value });
  }

const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const form = e.target;
      const formData = new FormData(form);
      let dataObj = {};
      for (const [key, value] of formData.entries()) {
          if (key === "password") {
              dataObj[key] = sha512(value);
          }
          else {
              dataObj[key] = value;
          }
      }
      let dataObjPass = {};
      dataObjPass['radius_limit'] = dataObj.radius_limit;
      const data = JSON.stringify(dataObjPass);
      dispatch(updateSetting(data));
    }
  }

const handleValidation = () => {
    let errors = {};
    let errorClassName = {};
    let formIsValid = true;

    if (!formValue.radius_limit) {
      formIsValid = false;
      errors["radius_limit"] = "Please enter radius.";
      errorClassName["radius_limit"] = "has-error";
      $('#radius_limit-error').html(errors["radius_limit"]);
    }
    return formIsValid;
  }
return (
<>
  <div id="page-container" className="header-fixed-top sidebar-visible-lg-full">
    <Sidebar />
    <div id="main-container">
      <Header />
      <div id="page-content" style={{ minHeight: '966px' }}>
        <div className="row block full user-block edit-profile">
          <div className="profile-sec">
            <div className="user-header">
              <div className="">
                <h2>App Setting</h2>
              </div>

              <form  id="add_form" name="add_form" className="form-horizontal edit-form" onSubmit={handleSubmit} method="POST">
                <input type="hidden" name="app_id" id="app_id" value={formValue.id || ""} />
                <div className="form-group">
                  <label className="col-xs-12 col-xs-12 col-sm-5 col-md-4 col-lg-3 control-label vcenter" for="Radius">Radius (KM)</label>
                  <div className="col-xs-12 col-xs-12 col-sm-6 col-md-6 col-lg-5 vcenter">
                    <input type="text" name="radius_limit" id="radius_limit" className="form-control" value={formValue.radius_limit || ""} onChange={onInputChange}  />
                    <div id="radius_limit-error" className="error help-block animation-slideUp"></div>
                  </div>
                </div>

                <div className="form-group form-actions">
                  <div className="row">
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-2 col-sm-offset-5 col-md-offset-4 col-lg-offset-3">
                      {btn_status === 'loading' ? (
                        <button type="submit" className=" common-btn btn-effect-ripple"
                        style={{overflow: "hidden", position: "relative"}} disabled><i class="fa fa-spinner" aria-hidden="true"></i> Update</button>
                      )
                      :
                        <button type="submit" className=" common-btn btn-effect-ripple"
                        style={{overflow: "hidden", position: "relative"}}>Update</button>
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ToastContainer />
</>
)
}
export default App_settings;