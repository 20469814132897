import {Routes, Route } from "react-router-dom";
import Main from './components/Dashboard';
import Login from './components/Login';
import ChangePassword from './components/ChangePassword';
import ForgotPassword from './components/ForgotPassword';
import Edit_profile from './components/Edit_profile';
import Providers from './components/providers/Providers';
import Seekers from './components/seekers/Seekers';
import ProvidersDetail from './components/providers/ProvidersDetail';
import SeekersDetail from './components/seekers/SeekersDetail';
import Jobs from './components/jobs/Jobs';
import JobDetail from './components/jobs/JobsDetail';
import ApplicantProfile from './components/jobs/ApplicantProfile';
import Cms from './components/cms/Cms';
import CmsEdit from './components/cms/Edit';
import Contactus from './components/contactus/Contactus';
import App_settings from './components/App_settings';
function App() {
  return (
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/change_password' element={<ChangePassword />} />
        <Route path='/dashboard' element={<Main />} />
        <Route path='/forgot_password' element={<ForgotPassword />} />
        <Route path='/edit_profile' element={<Edit_profile />} />
        <Route path='/job_providers' element={<Providers />} />
        <Route path="/job_providers_detail/:provider_id" element={<ProvidersDetail />} />
        <Route path='/job_seekers' element={<Seekers />} />
        <Route path="/job_seekers_detail/:seeker_id" element={<SeekersDetail />} />
        <Route path='/jobs' element={<Jobs />} />
        <Route path="/job_detail/:job_id" element={<JobDetail />} />
        <Route path="/applicant_profile/:apply_id" element={<ApplicantProfile />} />
        <Route path='/cms' element={<Cms />} />
        <Route path="/cms_edit/:cms_id" element={<CmsEdit />} />
        <Route path='/contactus' element={<Contactus />} />
        <Route path='/app_settings' element={<App_settings />} />
      </Routes>
  );
}

export default App;