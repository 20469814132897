import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { baseUrl } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import $ from 'jquery';

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const cmsSlice = createSlice({
    name: 'cms',
    initialState: {
        data: [],
        cmsData: [],
        cmsDetailData:{},
        totalRecord: 0,
        status: STATUSES.IDLE,
        updateStatus : STATUSES.IDLE,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            
            .addCase(getCmsList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCmsList.fulfilled, (state, action) => {
                state.cmsData = action.payload;
                state.totalRecord = action.payload.total_record;
                state.status = STATUSES.IDLE;
            })
            .addCase(getCmsList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(cmsDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(cmsDetails.fulfilled, (state, action) => {
                state.cmsDetailData = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(cmsDetails.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(updateCms.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.updateStatus = STATUSES.LOADING;
            })
            .addCase(updateCms.fulfilled, (state, action) => {
                state.status = STATUSES.IDLE;
                state.updateStatus = STATUSES.IDLE;
            })
            .addCase(updateCms.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
                state.updateStatus = STATUSES.ERROR;
            });

            
    },
});

export default cmsSlice.reducer;

// Thunks
export const getCmsList = createAsyncThunk('getCmsList', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/cmsList', payload,{
    headers: headers
  });
        if (response.data.success == 'yes') {
             return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const cmsDetails = createAsyncThunk('cmsDetails', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/cmsDetails', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            return response.data;
        }
        else {
            toast.error(response.data.message);
            return response.data;
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});

export const updateCms = createAsyncThunk('updateCms', async (payload) => {
    try {
        const headers = {
          'Content-Type': 'application/json',
        }
        const response = await axios.post(baseUrl + 'admin/updateCms', payload,{
            headers: headers
        });
        if (response.data.success == 'yes') {
            toast.success(response.data.message);
            return response.data.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        toast.error(error.message);
        return isRejectedWithValue(error.message);
    }
});